import React from "react";
import Responsive from "../../Components/Responsive";

const Pdf = ({ generalImage, stat }) => {
  return (
    <div>
      <p style={{ fontWeight: "bold", fontSize: 30, textAlign: "center" }}>
        Statistique
      </p>
      <Responsive className="p-10">
        <img src={generalImage} />
      </Responsive>
      {stat &&
        stat.map((el) => (
          <div className="p-10 " style={{ width: "50%", float: "right" }}>
            <div
              style={{
                background: "white",
                textAlign: "center",
                width: "100%",
              }}
            >
              <p style={{ fontWeight: 500, fontSize: 35 }}>{el.label}</p>
              <img src={el.image} alt="" width={"100%"} height={"100%"} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Pdf;
