export class CustomerModel {
  client = {
    name: "",
  };
  customer = {
    name: "",
    name_ar: "",
    cin: "",
    age: 0,
    city: "",
    phoneNumber: "",
    passportNumber: "",
  };
  disponibility = {
    date: "",
    airline: {
      name: "",
    },
  };
  price = {
    hotelMadinahName: "",
    hotelMakkahName: "",
  };
  surrveyState = 0;
  rabateur = "";
  guideName = "";
  saoudiWakil = "";
  makkahMandoub = "";
  madinaMandoub = "";
  disponibilityId = 0;
}
