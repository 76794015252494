import PageTopIcon from "@rsuite/icons/PageTop";
import React from "react";
import { Button, Checkbox, Input, InputPicker } from "rsuite";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";
import {
  chamberType,
  GoodOrBadResponse,
  surveySaoudiState,
  worstOf,
} from "../../data/data";

const AddSurvey = ({
  model,
  _setmodel,
  selectedMootamar,
  setselectedMootmar,
  update,
}) => {
  const handleChambervalue = (chamberNumber) => {
    if (chamberNumber) {
      _setmodel((prev) => ({
        ...prev,
        chamberNumber: parseInt(chamberNumber),
      }));
      setselectedMootmar((prev) => ({
        ...prev,
        makkahChamberNumber: parseInt(chamberNumber),
      }));
    } else {
      _setmodel((prev) => ({
        ...prev,
        chamberNumber: 0,
      }));
      setselectedMootmar((prev) => ({
        ...prev,
        makkahChamberNumber: 0,
      }));
    }
  };
  return (
    <div>
      <div className="p-10">
        <Responsive
          m={2.3}
          l={2.3}
          xl={2.3}
          // className="p-10"
          style={{
            height: 293,
            border: "solid 1px",
            marginRight: 3,
            padding: 5,
          }}
        >
          <img
            src={
              Base_URL2 +
              "Uploads/CustomerPictures/" +
              selectedMootamar.customer.picture
                ? Base_URL2 +
                  "Uploads/CustomerPictures/" +
                  selectedMootamar.customer.picture
                : "ok"
            }
            width={"100%"}
            height={"100%"}
          />
        </Responsive>
        <Responsive
          m={9.5}
          l={9.5}
          xl={9.5}
          className="p-10"
          style={{
            background: "#ecf0f1",
            border: "solid 1px",

            //  borderRadius: "10px",
          }}
        >
          <Responsive className="p-10">
            {/* <span
              style={{
                marginRight: 10,
                fontWeight: "bolder",
                fontSize: 20,
              }}
            >
              Nom:
            </span> */}
            <span style={{ fontSize: 25, fontWeight: "bolder" }}>
              {selectedMootamar.customer.name}
            </span>
          </Responsive>
          <Responsive m={4} l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Age :</p>
            <Input
              placeholder="Age "
              value={selectedMootamar.customer.age}
              onChange={(age) => {
                age
                  ? setselectedMootmar((prev) => ({
                      ...prev,
                      customer: {
                        ...selectedMootamar.customer,
                        age: parseInt(age),
                      },
                    }))
                  : setselectedMootmar((prev) => ({
                      ...prev,
                      customer: {
                        ...selectedMootamar.customer,
                        age: 0,
                      },
                    }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Numero de téléphone
            </p>
            <Input
              placeholder="Numero de téléphone "
              value={selectedMootamar.customer.phoneNumber}
              onChange={(phoneNumber) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: { ...selectedMootamar.customer, phoneNumber },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Hotel Makkah :</p>
            <Input
              readOnly
              placeholder="Hotel Makkah : "
              value={
                selectedMootamar.price.hotelMakkahName
                  ? selectedMootamar.price.hotelMakkahName
                  : ""
              }
              onChange={(hotelMakkahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMakkahName,
                    hotelMakkahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Hotel Madinah :
            </p>
            <Input
              readOnly
              placeholder="Hotel Madinah : "
              value={
                selectedMootamar.price.hotelMadinahName
                  ? selectedMootamar.price.hotelMadinahName
                  : ""
              }
              onChange={(hotelMadinahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMadinahName,
                    hotelMadinahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Nombre de Chambre
            </p>
            <Input
              placeholder="Nombre de Chambre"
              value={selectedMootamar.makkahChamberNumber}
              onChange={(chamberNumber) => handleChambervalue(chamberNumber)}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Type de Chambre
            </p>
            <InputPicker
              block
              data={chamberType}
              placeholder="Type de Chambre"
              value={selectedMootamar.makkahChamberType}
              onChange={(chamberType) => {
                _setmodel((prev) => ({ ...prev, chamberType }));
                setselectedMootmar((prev) => ({
                  ...prev,
                  makkahChamberType: chamberType,
                }));
              }}
            />
          </Responsive>
          <Responsive className="p-10" style={{ textAlign: "right" }}>
            <Button appearance="primary" onClick={update}>
              Enregistrer
            </Button>
          </Responsive>
        </Responsive>
      </div>

      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
          }}
        >
          تحديد موعد زيارة المعتمرين (قبل مغادرة مكة بيوم) و التواصل مع رئيس
          المجموعة قبل الاستبيان
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
        <span
          style={{
            marginRight: 5,
            fontSize: 18,
            display: "flex",
            justifyContent: "right",
            marginTop: 10,
          }}
        >
          التاكد من عدم وجود اي شخص (بما في ذلك رئيس المجموعة) عيد زيارة
          المعتمرين في غرفهم للاستبيان
          <span style={{ marginLeft: 5 }}>
            <PageTopIcon />
          </span>
        </span>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {surveySaoudiState.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space around",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(v) => {
                    setselectedMootmar((prev) => {
                      return { ...prev, surveyMakkahState: v };
                    });
                    _setmodel((prev) => {
                      return { ...prev, surveyState: v };
                    });
                  }}
                  checked={
                    selectedMootamar.surveyMakkahState == el.value
                      ? true
                      : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كانت أجواء العمرة
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(umrahReview) => {
                    _setmodel((prev) => {
                      return { ...prev, umrahReview };
                    });
                  }}
                  checked={model.umrahReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كانت اللهوة بيكم من طرف المرافق في مكة
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(guideMakkah) => {
                    _setmodel((prev) => {
                      return { ...prev, guideMakkah };
                    });
                  }}
                  checked={model.guideMakkah == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش تقييمك لرحلة الطائف
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(taifReview) => {
                    _setmodel((prev) => {
                      return { ...prev, taifReview };
                    });
                  }}
                  checked={model.taifReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش تقييمك للإحاطة الدينية في مكة
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(makkahReligiousbriefing) => {
                    _setmodel((prev) => {
                      return { ...prev, makkahReligiousbriefing };
                    });
                  }}
                  checked={
                    model.makkahReligiousbriefing == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش لقيت فندق مكة
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {GoodOrBadResponse.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(makkahHotel) => {
                    _setmodel((prev) => {
                      return { ...prev, makkahHotel };
                    });
                  }}
                  checked={model.makkahHotel == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          margin: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          شينة الحاجة اللي ما عجبتكش في خدمات كنوز طول فترة اقامتك في مكة
        </p>
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {worstOf.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(worstOfMakkah) => {
                    _setmodel((prev) => {
                      return { ...prev, worstOfMakkah };
                    });
                  }}
                  checked={model.worstOfMakkah == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </Responsive>
      {/* Note */}
      <Responsive
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          // borderRadiusBottom: "15px",
          marginBottom: 15,
        }}
      >
        <Input
          as="textarea"
          rows={10}
          value={model.note}
          onChange={(note) =>
            _setmodel((prev) => {
              return { ...prev, note };
            })
          }
        />
      </Responsive>
    </div>
  );
};

export default AddSurvey;
