"use client";
import React, { useEffect, useState } from "react";
import { Checkbox, Input, InputPicker, SelectPicker } from "rsuite";
import {
  guide,
  BestInKounouz,
  UmrahState,
  airlineTreatment,
  branchTreatment,
  customerType,
  firstTime,
  isRecommended,
  heardOf,
  hotelMaddina,
  hotelMakkah,
  madinahServices,
  makkahServices,
  surveyStatedata,
  Situation,
} from "../../data/data";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";

const AddSurvey = ({
  model,
  _setmodel,
  selectedMootamar,
  setselectedMootmar,
  Checked,
  setChecked,
}) => {
  // useEffect(() => console.log(selectedMootamar), [selectedMootamar]);

  return (
    <div style={{ padding: "10px", fontSize: 17 }}>
      <div className="p-10">
        <Responsive
          m={2}
          l={2}
          xl={2}
          style={{
            height: 237,
            border: "solid 1px",
            marginRight: 3,
            padding: 5,
          }}
        >
          <img
            src={
              Base_URL2 +
              "Uploads/CustomerPictures/" +
              selectedMootamar.customer.picture
            }
            width={"100%"}
            height={"100%"}
          />
        </Responsive>
        <Responsive
          m={9.8}
          l={9.8}
          xl={9.8}
          className="p-10"
          style={{
            background: "#ecf0f1",
            border: "solid 1px",
            marginLeft: "5px",

            //  borderRadius: "10px",
          }}
        >
          <Responsive className="p-10">
            <span style={{ fontSize: 25, fontWeight: "bolder" }}>
              {selectedMootamar.customer.name}
            </span>
          </Responsive>
          <Responsive m={4} l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Age :</p>
            <Input
              placeholder="Age "
              value={selectedMootamar.customer.age}
              onChange={(age) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.customer,
                    age: parseInt(age),
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Numero de téléphone
            </p>
            <Input
              placeholder="Numero de téléphone "
              value={selectedMootamar.customer.phoneNumber}
              onChange={(phoneNumber) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: { ...selectedMootamar.customer, phoneNumber },
                }));
              }}
            />
          </Responsive>
          <Responsive m={4} l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Agence :</p>
            <Input
              readOnly
              placeholder="Agence "
              value={selectedMootamar.client.name}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Guide :</p>
            <Input
              value={selectedMootamar.guideName}
              onChange={(guideName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  guideName,
                }));
                _setmodel((prev) => ({
                  ...prev,
                  guideName: guideName,
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Hotel Makkah :</p>
            <Input
              readOnly
              placeholder="Hotel Makkah : "
              value={
                selectedMootamar.price
                  ? selectedMootamar.price.hotelMakkahName
                  : ""
              }
              onChange={(hotelMakkahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMakkahName,
                    hotelMakkahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>
              Hotel Madinah :
            </p>
            <Input
              readOnly
              placeholder="Hotel Madinah : "
              value={
                selectedMootamar.price
                  ? selectedMootamar.price.hotelMadinahName
                  : ""
              }
              onChange={(hotelMadinahName) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.price.hotelMadinahName,
                    hotelMadinahName,
                  },
                }));
              }}
            />
          </Responsive>
          <Responsive m={4} l={4} xl={3} className="p-10">
            <p style={{ fontWeight: "bolder", fontSize: 17 }}>Ville :</p>
            <Input
              placeholder="Age "
              value={selectedMootamar.customer.city}
              onChange={(city) => {
                setselectedMootmar((prev) => ({
                  ...prev,
                  customer: {
                    ...selectedMootamar.customer,
                    city,
                  },
                }));
              }}
            />
          </Responsive>
        </Responsive>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <div>
          <h3 style={{ textAlign: "center", fontSize: 35, marginBottom: 10 }}>
            ترحيب
          </h3>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <b>{" " + selectedMootamar.customer.name + " "} </b> عسلامة
            {selectedMootamar.customer.gender == 1 ? " سي " : " مدام "}
          </p>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <span>
              من قسم الجودة بكنوز. حبينا نشكروك لاختيارك كنوز، نطمنوا عليك
            </span>
            <span
              style={{
                fontWeight: "bolder",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {JSON.parse(localStorage.getItem("auth")).fullName + "  "}
            </span>
            <span> شنية أحوالك؟ إن شاء الله تكون بخير... معاك </span>
          </p>

          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              // float: "right",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <span> ممكن ناخذو رآيك لو تسمح في أقل من </span>

            {selectedMootamar
              ? selectedMootamar.client.name
              : " _____________ "}

            <span>مع كنوز </span>
            <span>
              {selectedMootamar
                ? selectedMootamar.disponibility.date.substring(0, 10)
                : " 99/99/9999 "}
            </span>
            <span>انت عملت معانا عمرة في</span>
          </p>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              // float: "right",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <span>دقيقتين؟</span>
          </p>
        </div>
      </div>

      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
        className=""
      >
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {surveyStatedata.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space around",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(v) => {
                    setselectedMootmar((prev) => {
                      return { ...prev, surrveyState: v };
                    });
                    _setmodel((prev) => {
                      return { ...prev, surveyState: v };
                    });
                  }}
                  checked={
                    selectedMootamar.surrveyState == el.value ? true : false
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
        className=""
      >
        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {customerType.map((el, key) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space around",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(v) => {
                    setselectedMootmar((prev) => {
                      let selectedMootamarCopy = { ...prev };
                      selectedMootamarCopy.customer.customerType = v;
                      return selectedMootamarCopy;
                    });
                  }}
                  checked={
                    selectedMootamar.customer.customerType == el.value
                      ? true
                      : false
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
        className=""
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش سمعت بعمرة كنوز؟
        </p>

        <div
          style={{
            margin: "auto",
            width: "95%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {heardOf.map((el, key) => {
            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  {el.label}
                  <Checkbox
                    value={el.value}
                    onChange={(v) => {
                      _setmodel((prev) => {
                        return { ...prev, methodOfHearingAbout: v };
                      });
                    }}
                    checked={
                      model.methodOfHearingAbout == el.value ? true : false
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          هل هذه اول رحلة مع كنوز؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {firstTime.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  onChange={(isFirstTime) =>
                    _setmodel((prev) => {
                      return { ...prev, isFirstTime };
                    })
                  }
                  checked={model.isFirstTime == el.value ? true : false}
                  value={el.value}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كانت عمرتك مع كنوز عموما؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {UmrahState.map((el, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(generalReview) =>
                    _setmodel((prev) => {
                      return { ...prev, generalReview };
                    })
                  }
                  checked={model.generalReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          ؟{selectedMootamar ? selectedMootamar.client.name : " kounouz "}
          <span style={{ marginLeft: 5 }}>
            كيفاش كان إنطباعك بعد ما مشيت مع فرع
          </span>
        </p>

        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {branchTreatment.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  onChange={(branchReview) =>
                    _setmodel((prev) => {
                      return { ...prev, branchReview };
                    })
                  }
                  checked={model.branchReview == el.value ? true : false}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          <span style={{ marginRight: 5 }}>كيفاش كانت خدماته؟</span>
          {selectedMootamar.guideName
            ? selectedMootamar.guideName
            : " ___________ "}
          <span style={{ marginLeft: 5 }}>انتوما مشيتو مع المرافق/ة</span>
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {guide.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.guideReview == el.value ? true : false}
                  onChange={(guideReview) =>
                    _setmodel((prev) => {
                      return { ...prev, guideReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          <span style={{ marginRight: 5 }}>الانتظار، الراحة البقاج؟، </span>
          {selectedMootamar.disponibility
            ? selectedMootamar.disponibility.airline.name
            : "  ____________ "}
          <span style={{ marginLeft: 5 }}>
            كيفاش كانت الرحلة على خطوط الطيران
          </span>
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {airlineTreatment.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.airlineReview == el.value ? true : false}
                  onChange={(airlineReview) =>
                    _setmodel((prev) => {
                      return { ...prev, airlineReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          <span style={{ marginRight: 5 }}>
            ، كيفاش تقيم الموقع، الخدمات متاع الفندق و الراحة؟
          </span>
          {selectedMootamar.price
            ? selectedMootamar.price.hotelMadinahName
            : " ___________"}
          <span style={{ marginLeft: 5 }}> في المدينة سكنت في فندق</span>
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {hotelMaddina.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.madinahHotelReview == el.value ? true : false}
                  onChange={(madinahHotelReview) =>
                    _setmodel((prev) => {
                      return { ...prev, madinahHotelReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان الاستقبال في الفندق، مواعيد المزارات ، زيارة الروضة، تحويل
          الباقاج من المدينة ؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {madinahServices.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={
                    model.madinahServiceReview == el.value ? true : false
                  }
                  onChange={(madinahServiceReview) =>
                    _setmodel((prev) => {
                      return { ...prev, madinahServiceReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          <span style={{ marginRight: 5 }}>
            نفس الشيئ الموقع، الخدمات متاع الفندق و الراحة؟
          </span>
          {selectedMootamar.price
            ? selectedMootamar.price.hotelMakkahName
            : " ___________ "}
          <span style={{ marginLeft: 5 }}> بخصوص مكة كيفاش تقيم فندق</span>
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {hotelMakkah.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.makkahHotelReview == el.value ? true : false}
                  onChange={(makkahHotelReview) =>
                    _setmodel((prev) => {
                      return { ...prev, makkahHotelReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          كيفاش كان الاستقبال في الفندق، مواعيد المزارات ، تحويل الباقاج الى
          المطار؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {makkahServices.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.makkahServiceReview == el.value ? true : false}
                  onChange={(makkahServiceReview) =>
                    _setmodel((prev) => {
                      return { ...prev, makkahServiceReview };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          height: 175,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          شنية أكثر حاجة عجبتك في كنوز؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {BestInKounouz.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  value={el.value}
                  checked={model.bestOf == el.value ? true : false}
                  onChange={(bestOf) =>
                    _setmodel((prev) => {
                      return { ...prev, bestOf };
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 5,
          // height: 150,
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "#0000ff",
          }}
        >
          تحب تعاود مع كنوز و الا تنصح بيها لاحبابك؟
        </p>
        <div
          style={{
            width: "95%",
            margin: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap-reverse",
          }}
        >
          {isRecommended.map((el, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {el.label}
                <Checkbox
                  onChange={(isRecommended) =>
                    _setmodel((prev) => {
                      return { ...prev, isRecommended };
                    })
                  }
                  checked={model.isRecommended == el.value ? true : false}
                  value={el.value}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Responsive>
        <p
          style={{
            marginRight: 5,
            fontSize: 18,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "right",
            color: "red",
          }}
        >
          خطورة الوضع
        </p>
        <div
          className="flex justify-right bg-red-500"
          style={{ display: "flex", justifyContent: "right" }}
        >
          <SelectPicker
            data={Situation}
            style={{ width: 250, marginBottom: 10, marginTop: 10 }}
            searchable={false}
            value={model.situationState}
            onChange={(situationState) =>
              _setmodel((prev) => {
                return { ...prev, situationState };
              })
            }
          />
        </div>
      </Responsive>
      {/* Note */}
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <Input
          as="textarea"
          rows={10}
          value={model.note}
          onChange={(note) =>
            _setmodel((prev) => {
              return { ...prev, note };
            })
          }
        />
      </div>
      <div
        style={{
          border: "solid 1px rgb(226,238,224)",
          padding: 10,
          background: "#ccffcc",
          borderRadius: "15px",
          marginBottom: 15,
        }}
      >
        <div>
          <h3 style={{ textAlign: "center" }}></h3>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              textAlign: "right",
            }}
          >
            نشكروك برشة على الثقة متاعك في كنوز
          </p>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              textAlign: "right",
            }}
          >
            <span style={{ color: "green" }}>
              في حال الرضاء والمعتمر من احدى فروع السونترال :
            </span>
            حبينا نهديولك وصل تخفيض بقيمة 200 دينار لاي عمرة في الموسم هذا تنجم
            تستعمله انت او تهديه لاي اقاربك او احبابك.
          </p>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              textAlign: "right",
            }}
          >
            <span style={{ color: "red" }}>
              في حال عدم الرضاء والمعتمر من احدى فروع السونترال:
            </span>
            حبينا نعتذرو على اللي صار ونشوفوا كيفاش نرضيوك. توا عندك كادو من
            عندنا وصل تخفيض بقيمة 200 دينار لاي عمرة في الموسم هذا تنجم تستعمله
            انت او تهديه لاي اقاربك او احبابك. و ان شاء الله تزورنا في فرعنا
            بال**** و نشوفوا كيفاش نرضيوك ان شاء الله... نعتذرو مرة أخرى
          </p>
          <p
            style={{
              marginRight: 5,
              fontSize: 18,
              textAlign: "right",
            }}
          >
            بارك الله فيك على وقتك و ان شاء الله نشوفك معانا في كنوز على قريب...
            بالسلامة
          </p>
        </div>
      </div>
      <div
        className=" shadow-md"
        style={{
          height: 50,
          display: "flex",
          width: 180,
          alignItems: "center",
          background: "rgb(238,238,238)",
          boxShadow:
            "  0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        }}
      >
        <div
          style={{
            width: 30,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            value={Checked}
            onChange={() => {
              setChecked(!Checked);
            }}
            checked={Checked ? true : false}
          />
        </div>
        <div
          style={{
            width: 150,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span> Envoyer Coupon </span>
        </div>
      </div>
    </div>
  );
};

export default AddSurvey;
