import { Skeleton } from "@mui/material";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import React, { useEffect, useState } from "react";
import { IconButton, Nav, SelectPicker } from "rsuite";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import GeneralStats from "../../Components/Stat/GeneralStats";
import Stat from "../../Components/Stat/Stat";
import ReactDOMServer from "react-dom/server";

import moment from "moment";
import {
  BestInKounouz,
  Colors,
  GeneralStatColors,
  UmrahState,
  generalLabels,
  goodBadResponse,
  heardOf,
  surveyTitles,
  yesOrNoResponse,
} from "../../data/dashboardData";
import Airlines from "./Airlines";
import Hotels from "./Hotels";
import MadinahStat from "./MadinahStat";
import Makkah from "./MakkahStat";
/////
import { Chart as ChartJS } from "chart.js";
import Pdf from "./Pdf";

const Dashboard = () => {
  const [filterModel, setfilterModel] = useState({
    q: null,
    clientId: null,
    disponibilityId: 0,
    seasonId: 0,
    startDate: "",
    endDate: "",
  });

  const [active, setActive] = useState("general");
  const [statData, setstatData] = useState({});
  const [GeneralData, setGeneralData] = useState([]);
  const [dates, setdates] = useState([]);
  const [seasons, setseasons] = useState([]);
  ///------------Dates after November-----------------///

  const [clients, setclients] = useState([]);

  const [hotels, setHotels] = useState([]);
  const [Airline, setAirline] = useState([]);

  const [answered, setAnswered] = useState(1);
  const [DataKeys, setDataKeys] = useState([]);

  const GetData = async () => {
    let res = await createAPIEndpoint("/Statistics", filterModel).fetch();

    delete res.data.hasGift;
    setstatData(res.data);
    setDataKeys(Object.keys(res.data));
    createAPIEndpoint("Statistics/oldCustomer", filterModel)
      .fetch()
      .then((res) => {
        const dataToUse = res.data
          .sort((a, b) => a.key - b.key)
          .filter((el) => el.key != 0);
        setGeneralData(dataToUse);
        let total = 0;
        dataToUse
          .filter((el) => el.key == 1 || el.key == 4)
          .map((el) => {
            total += el.count;
          });

        setAnswered(total);
      })
      .catch((err) => console.log(err));
  };
  /////----------Get Seasons----------/////
  const getSeasons = () => {
    createAPIEndpoint("Season", { take: 1000, page: 1 })
      .fetch()
      .then((res) => {
        setseasons(
          res.data.data.map((el, index) => ({
            label: el.designation,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const labels = (index) => {
    if (Object.keys(statData)[index] == "methodOfHearingAbout")
      return heardOf
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "isFirstTime")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "generalReview")
      return UmrahState.map((el) => {
        return el.label;
      }).reverse();
    else if (DataKeys[index] == "branchReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "guideReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "airlineReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "madinahHotelReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "madinahServiceReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "makkahHotelReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "makkahServiceReview")
      return goodBadResponse
        .map((el) => {
          return el.label;
        })
        .reverse();
    else if (DataKeys[index] == "bestOf")
      return BestInKounouz.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "isRecommended")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
    else if (DataKeys[index] == "isCouponUsed")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
  };

  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 500,
    })
      .fetch()
      .then((res) => {
        setdates(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((el) => ({
              label: el.airline
                ? moment(el.date).format("DD/MM/YYYY") +
                  " -- " +
                  el.airline.fullName
                : "",
              value: el.id,
            }))
        );
      })
      .catch((err) => console.log(err));
  };
  const getHotels = () => {
    createAPIEndpoint("Statistics/Hotels")
      .fetch()
      .then((res) => {
        setHotels(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getAirlinesStatics = () => {
    createAPIEndpoint("Statistics/Airline", filterModel)
      .fetch()
      .then((res) => {
        setAirline(res.data);
      })
      .catch((err) => console.log(err));
  };

  /////////------------------------DownloadPDF
  const DownloadPDF = () => {
    let generalImage = "";
    const canvas = document.getElementById("bar-chart-canvas");

    // console.log("bar", canvas);
    if (canvas) {
      const chartInstance = ChartJS.getChart(canvas);
      if (chartInstance) {
        const base64Image = chartInstance.toBase64Image();
        generalImage = base64Image;
      }
    }
    let stat = [];
    surveyTitles.map((element, index) => {
      const canvas = document.getElementById(`bar${index}`);
      let title = surveyTitles[index];
      if (canvas) {
        const chartInstance = ChartJS.getChart(canvas);
        if (chartInstance) {
          const base64Image = chartInstance.toBase64Image();

          stat = [...stat, { label: title, image: base64Image }];
        }
      }
    });

    const content = ReactDOMServer.renderToStaticMarkup(
      <Pdf stat={stat} generalImage={generalImage} />
    );

    const documentContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Statistiques</title>
          <style>
            body { font-family: Arial, sans-serif; -webkit-print-color-adjust: exact; 
        print-color-adjust: exact;  
         }
         @media print {
            @page {
              size: A4;        
              }
           
              }
  
        
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `;

    // Create a hidden iframe to inject and download as a file
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();

    doc.write(documentContent);
    doc.close();

    // Wait until the iframe content is loaded before triggering print
    iframe.onload = () => {
      // You could do any final checks here if needed, like checking if images are fully loaded inside iframe
      iframe.contentWindow.print();
      // Remove the iframe after printing
      iframe.remove();
    };
  };
  useEffect(() => {
    GetData();
    getDates();
    getClients();
    getHotels();
    getAirlinesStatics();
    getSeasons();
  }, []);

  useEffect(() => console.log(GeneralData), [GeneralData]);
  return GeneralData.length != 0 && answered ? (
    <div className="" style={{ minHeightheight: "100vh" }}>
      <div style={{ height: 36, backgroundColor: "white" }}>
        <Navbar appearance="tabs" active={active} onSelect={setActive} />
      </div>
      {active == "general" && (
        <div>
          <Filter className="p-10" search={GetData}>
            <Responsive className="p-10 " l={3} xl={3}>
              <label htmlFor=""> Date :</label>
              <SelectPicker
                data={dates}
                block
                value={filterModel.disponibilityId}
                onChange={(disponibilityId) => {
                  setfilterModel((prev) => {
                    return { ...prev, disponibilityId };
                  });
                }}
              />
            </Responsive>
            <Responsive className="p-10 " l={3} xl={3}>
              <label htmlFor=""> Agence :</label>
              <SelectPicker
                data={clients}
                block
                value={filterModel.clientId}
                onChange={(clientId) => {
                  setfilterModel((prev) => {
                    return { ...prev, clientId };
                  });
                }}
              />
            </Responsive>

            {/* <Responsive l={3} xl={3} className="p-10">
              <label>Plage de dates: </label>
              <DateRangePicker
                block
                value={filterModel.dateRange}
                // placeholder="Rechercher"
                onChange={(q) =>
                  q
                    ? setfilterModel((prev) => ({
                        ...prev,
                        dateRange: q,
                        startDate: q[0],
                        endDate: q[1],
                      }))
                    : setfilterModel((prev) => ({
                        ...prev,
                        dateRange: "",
                        startDate: "",
                        endDate: "",
                      }))
                }
              />
            </Responsive> */}
            {/* <Responsive className="p-10 " l={3} xl={3}>
              <label htmlFor=""> Seasons :</label>
              <SelectPicker
                data={[{ label: "Tout", value: 0 }, ...seasons]}
                block
                value={filterModel.seasonId}
                onChange={(seasonId) => {
                  setfilterModel((prev) => {
                    return { ...prev, seasonId };
                  });
                }}
              />
            </Responsive> */}
          </Filter>
          <Responsive className="p-10" style={{ textAlign: "right" }}>
            <IconButton
              appearance="primary"
              color="red"
              icon={<FileDownloadIcon />}
              onClick={DownloadPDF}
            >
              Download PDF
            </IconButton>
          </Responsive>

          <Responsive className="p-10">
            <GeneralStats
              data={GeneralData}
              backgroundColors={GeneralStatColors}
              labels={generalLabels}
            />
          </Responsive>

          {statData &&
            Object.keys(statData).map((el, index) => {
              let title = surveyTitles[index];
              let Label = labels(index);
              return (
                <Responsive
                  xl={6}
                  l={6}
                  className="p-10 "
                  style={{ float: "right" }}
                >
                  <Stat
                    number={index}
                    Answered={answered}
                    backgroundColors={Colors}
                    title={title}
                    data={statData[DataKeys[index]]}
                    labels={Label}
                  />
                </Responsive>
              );
            })}
        </div>
      )}
      {active == "hotels" && (
        <div>
          <Hotels hotels={hotels} />
        </div>
      )}
      {/* {active == "Guides" && (
        <div>
          <Guides />
        </div>
      )} */}
      {active == "Airlines" && (
        <div>
          <Airlines Airlines={Airline} />
        </div>
      )}
      {active == "MadinahStat" && (
        <div>
          <MadinahStat />
        </div>
      )}
      {active == "MakkahStat" && (
        <div>
          <Makkah />
        </div>
      )}
    </div>
  ) : (
    <div>
      <Responsive className="p-10">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
      <Responsive xl={6} l={6} className="p-10 ">
        <Skeleton variant="h1" animation="wave" height="40vh" />
      </Responsive>
    </div>
  );
};

export default Dashboard;

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ marginBottom: 50 }}
    >
      <Nav.Item eventKey="general">General</Nav.Item>
      <Nav.Item eventKey="hotels">Hotels</Nav.Item>
      <Nav.Item eventKey="Airlines">Airlines</Nav.Item>
      <Nav.Item eventKey="MadinahStat">Madinah statistique</Nav.Item>
      <Nav.Item eventKey="MakkahStat">Makkah statistique</Nav.Item>
    </Nav>
  );
};
