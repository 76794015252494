import React, { useState } from "react";
import { Button, DateInput, DatePicker, Input } from "rsuite";
import Responsive from "../../Components/Responsive";
import { FlayersModel } from "../../Models/Flayers";
import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import Page1 from "./Page1";
import Page2 from "./Page2";
import { createAPIEndpoint } from "../../API/authenticated.request";

const Flayers = () => {
  const [model, setmodel] = useState(new FlayersModel());

  const pdfGenerator = (id) => {
    const content = ReactDOMServer.renderToStaticMarkup(
      id == 1 ? <Page1 model={model} /> : <Page2 model={model} />
    );
    const documentContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title></title>
          <style>    
            body { font-family: Arial, sans-serif; -webkit-print-color-adjust: exact;
             color-adjust: exact; }
              @media print {
          @page {
            size: A5;        
            }
            }

          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `;

    // Create a hidden iframe to inject and download as a file
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();

    doc.write(documentContent);
    doc.close();

    // Wait until the iframe content is loaded before triggering print
    iframe.onload = () => {
      // You could do any final checks here if needed, like checking if images are fully loaded inside iframe
      iframe.contentWindow.print();
      // Remove the iframe after printing
      iframe.remove();
    };
  };

  return (
    <div className="p-10">
      <div
        className="p-10"
        style={{ backgroundColor: "white", textAlign: "right" }}
      >
        <Responsive l={4} xl={3.5} className="p-10">
          <label htmlFor="">:مندوب مكة</label>
          <Input
            value={model.mandoubMakkah}
            onChange={(mandoubMakkah) =>
              setmodel((prev) => ({ ...prev, mandoubMakkah }))
            }
            style={{ direction: "rtl" }}
          />
        </Responsive>
        <Responsive
          l={4}
          xl={3.5}
          className="p-10"
          style={{ textAlign: "right" }}
        >
          <label htmlFor=""> :مندوب مدينة </label>
          <Input
            value={model.mandoubMadinah}
            onChange={(mandoubMadinah) =>
              setmodel((prev) => ({ ...prev, mandoubMadinah }))
            }
            style={{ direction: "rtl" }}
          />
        </Responsive>
        <Responsive
          l={4}
          xl={3.5}
          className="p-10"
          style={{ textAlign: "right" }}
        >
          <label htmlFor=""> : رئيس المجموعة </label>
          <Input
            value={model.leader}
            onChange={(leader) => setmodel((prev) => ({ ...prev, leader }))}
            style={{ direction: "rtl" }}
          />
        </Responsive>
      </div>
      <div
        style={{ backgroundColor: "white", textAlign: "right", padding: 10 }}
      >
        <Button
          appearance="primary"
          style={{ width: 150, fontSize: 15 }}
          onClick={() => pdfGenerator(1)}
        >
          طباعة
        </Button>
      </div>
      <div
        className="p-10"
        style={{ backgroundColor: "white", textAlign: "right" }}
      >
        <Responsive xl={5} className="p-10">
          <label htmlFor=""> تاريخ الرّجوع </label>
          <DatePicker
            format="dd/MM/yyyy"
            block
            value={model.dateOfReturn}
            onChange={(dateOfReturn) =>
              setmodel((prev) => ({ ...prev, dateOfReturn }))
            }
          />
        </Responsive>
        <Responsive xl={5} className="p-10">
          <label htmlFor=""> تاريخ الانطلاق </label>
          <DatePicker
            format="dd/MM/yyyy"
            block
            value={model.dateOfDeparture}
            onChange={(dateOfDeparture) =>
              setmodel((prev) => ({ ...prev, dateOfDeparture }))
            }
          />
        </Responsive>
        <Responsive xl={5} className="p-10">
          <label htmlFor="">مطار الرّجوع</label>
          <Input
            value={model.returnAirport}
            onChange={(returnAirport) =>
              setmodel((prev) => ({ ...prev, returnAirport }))
            }
            style={{ direction: "rtl" }}
          />
        </Responsive>
        <Responsive xl={5} className="p-10">
          <label htmlFor=""> مطار الانطلاق </label>
          <Input
            value={model.departureAirport}
            onChange={(departureAirport) =>
              setmodel((prev) => ({ ...prev, departureAirport }))
            }
            style={{ direction: "rtl" }}
          />
        </Responsive>
      </div>
      <div
        className="p-10"
        style={{ backgroundColor: "white", textAlign: "right" }}
      >
        <Responsive xl={6} l={6}>
          <Responsive className="p-10">
            <h3 htmlFor="">:برنامج مكة</h3>
            {model.programMakkah.map((el, index) => {
              return (
                <div>
                  <h5 htmlFor="">{`  اليوم ${days[index]}`}</h5>
                  <Input
                    value={el}
                    onChange={(program) => {
                      let modelCopy = { ...model };
                      let programMakkahCopy = [...modelCopy.programMakkah];
                      programMakkahCopy[index] = program;
                      modelCopy = {
                        ...model,
                        programMakkah: programMakkahCopy,
                      };
                      setmodel(modelCopy);
                    }}
                    style={{ marginTop: 10, direction: "rtl" }}
                  />
                </div>
              );
            })}
          </Responsive>
          <Responsive className="p-10">
            <Button
              appearance="primary"
              color="red"
              style={{ width: 150, fontSize: 15 }}
              onClick={() => {
                let modelCopy = { ...model };
                let programMakkahCopy = [...modelCopy.programMakkah];
                programMakkahCopy.pop();
                modelCopy.programMakkah = programMakkahCopy;
                console.log(modelCopy);

                setmodel(modelCopy);
              }}
              disabled={model.programMakkah.length == 1}
            >
              افسخ
            </Button>
            <Button
              appearance="primary"
              style={{ width: 150, fontSize: 15, marginLeft: 10 }}
              onClick={() => {
                let modelCopy = { ...model };
                let programMakkahCopy = [...modelCopy.programMakkah];
                programMakkahCopy.push("");
                modelCopy.programMakkah = programMakkahCopy;
                console.log(modelCopy);

                setmodel(modelCopy);
              }}
              disabled={model.programMakkah.length >= 10}
            >
              إضافة
            </Button>
          </Responsive>
        </Responsive>

        <Responsive xl={6} l={6}>
          <Responsive className="p-10">
            <h3 htmlFor="">:برنامج المدينة</h3>
            {model.programMadinah.map((el, index) => {
              return (
                <div>
                  <h5 htmlFor="">{`  اليوم ${days[index]}`}</h5>
                  <Input
                    value={el}
                    onChange={(program) => {
                      let modelCopy = { ...model };
                      let programMadinahCopy = [...modelCopy.programMadinah];
                      programMadinahCopy[index] = program;
                      modelCopy = {
                        ...model,
                        programMadinah: programMadinahCopy,
                      };
                      setmodel(modelCopy);
                    }}
                    style={{ marginTop: 10, direction: "rtl" }}
                  />
                </div>
              );
            })}
            <Responsive className="p-10">
              <Button
                appearance="primary"
                color="red"
                style={{ width: 150, fontSize: 15 }}
                onClick={() => {
                  let modelCopy = { ...model };
                  let programMadinahCopy = [...modelCopy.programMadinah];
                  programMadinahCopy.pop();
                  modelCopy.programMadinah = programMadinahCopy;
                  console.log(modelCopy);

                  setmodel(modelCopy);
                }}
                disabled={model.programMadinah.length == 1}
              >
                افسخ
              </Button>
              <Button
                appearance="primary"
                style={{ width: 150, fontSize: 15, marginLeft: 10 }}
                onClick={() => {
                  let modelCopy = { ...model };
                  let programMadinahCopy = [...modelCopy.programMadinah];
                  programMadinahCopy.push("");
                  modelCopy.programMadinah = programMadinahCopy;
                  console.log(modelCopy);

                  setmodel(modelCopy);
                }}
                disabled={model.programMadinah.length >= 10}
              >
                إضافة
              </Button>
            </Responsive>
          </Responsive>
          <div
            style={{
              backgroundColor: "white",
              textAlign: "right",
              padding: 10,
            }}
          >
            <Button
              appearance="primary"
              style={{ width: 150, fontSize: 15 }}
              onClick={() => pdfGenerator(2)}
            >
              طباعة
            </Button>
          </div>
        </Responsive>
      </div>
    </div>
  );
};

export default Flayers;

const days = [
  "الأوّل",
  "الثّاني",
  "الثالث",
  "الرّابع",
  "الخامس",
  "السّادس",
  "السّابع",
  "الثّامن",
  "التّاسع",
  "العاشر",
];
