import React, { useEffect, useState } from "react";
import { Button, CheckPicker, Input, InputNumber, InputPicker } from "rsuite";
import { genderLikeBackEnd } from "../../data/data";
import { createAPIEndpoint } from "../../API/authenticated.request";
import Responsive from "../../Components/Responsive";
import { GuideModel } from "../../Models/Guide";
import Swal from "sweetalert2";

const AddEdit = ({ model, _setmodel }) => {
  const addGuide = () => {
    let guideModel = new GuideModel();
    guideModel.picture = model.customer.picture;
    guideModel.firstName = model.customer.name;
    guideModel.age = model.customer.age;
    guideModel.gender = model.customer.gender == 1 ? "Homme" : "Femme";
    guideModel.phoneNumber = model.customer.phoneNumber;
    guideModel.cin = model.customer.cin;
    guideModel.passportNumber = model.customer.passportNumber;
    guideModel.clientId = model.clientId;
    createAPIEndpoint("Guide")
      .create(guideModel)
      .then(() =>
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "تمّت اضافة المرافق",
          showConfirmButton: false,
          timer: 1500,
        })
      )
      .catch((err) => {
        console.log(err);
        if (err.response.data == "Guide already existed")
          Swal.fire({
            icon: "error",
            text: " !!!!! المرافق موجود ",
          });
      });
  };

  return model.customer ? (
    <div>
      <div
        style={{
          position: "relative",
          backgroundColor: "#4d4cac",
          color: "white",
          padding: 15,
        }}
      >
        {model.customer.customerType == 2 && (
          <Button
            style={{ position: "absolute", top: 10, right: 25 }}
            onClick={addGuide}
            appearance="primary"
            color="yellow"
          >
            Ajouter Guide
          </Button>
        )}
        <Responsive xl={4} l={4} m={4} className="p-10">
          <label htmlFor="">Nom : </label>
          <Input
            readOnly
            value={model.customer.name ? model.customer.name : ""}
          />
        </Responsive>

        <Responsive xl={4} l={4} m={4} className="p-10">
          <label>age : </label>

          <InputNumber readOnly value={model.customer.age} />
        </Responsive>
        <Responsive xl={4} l={4} m={4} className="p-10">
          <label htmlFor="">genre : </label>
          <InputPicker
            block
            readOnly
            value={model.customer.gender}
            data={genderLikeBackEnd.map((element) => element)}
          />
        </Responsive>

        <Responsive xl={3} l={3} m={3} className="p-10">
          <label htmlFor="">CIN : </label>
          <Input readOnly value={model.customer.cin} />
        </Responsive>
        <Responsive xl={3} l={3} m={3} className="p-10">
          <label htmlFor="">Ville : </label>
          <Input readOnly value={model.customer.city} />
        </Responsive>
        <Responsive xl={3} l={3} m={3} className="p-10">
          <label htmlFor="">Numero Passeport : </label>
          <Input readOnly value={model.customer.passportNumber} />
        </Responsive>
        <Responsive xl={3} l={3} m={3} className="p-10">
          <label htmlFor="">Numero Téléphone : </label>
          <Input
            value={model.customer.phoneNumber}
            onChange={(v) => {
              _setmodel((prev) => {
                let modelCopy = { ...prev };
                let customerCopy = { ...modelCopy.customer };
                customerCopy.phoneNumber = v;
                modelCopy.customer = customerCopy;
                return modelCopy;
              });
            }}
          />
        </Responsive>
      </div>
      <Responsive>
        <label htmlFor="">Date Umrah : </label>
        <Input
          readOnly
          type="date"
          value={model.date ? model.disponibility.date.substring(0, 10) : ""}
        />
      </Responsive>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          gap: 5,
        }}
      >
        <Responsive m={3} l={3} xl={3} className="p-10">
          <label htmlFor="">Branche: </label>
          <Input readOnly value={model.client.name} />
        </Responsive>

        <Responsive m={3} l={3} xl={3} className="p-10">
          <label htmlFor="">Rabateur </label>
          <Input
            value={model.rabateur}
            onChange={(rabateur) => {
              _setmodel((prev) => {
                return { ...prev, rabateur };
              });
            }}
          />
        </Responsive>
        <Responsive m={3} l={3} xl={3} className="p-10">
          <label htmlFor="">Guide : </label>
          <Input
            value={model.guideName}
            onChange={(guideName) => {
              _setmodel((prev) => {
                return { ...prev, guideName };
              });
            }}
          />
        </Responsive>
        <Responsive m={3} l={3} xl={3} className="p-10">
          <label htmlFor="">airlineCompany : </label>
          <Input
            readOnly
            value={
              model.disponibility.airline
                ? model.disponibility.airline.name
                : ""
            }
          />
        </Responsive>
      </div>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label htmlFor="">Makkah Hotel : </label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/logoMakka.png" alt="" height={50} width={50} />
          <Input
            readOnly
            value={model.price ? model.price.hotelMakkahName : ""}
          />
        </div>
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label htmlFor="">Madina Hotel : </label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/logoMadinah.png" alt="" height={50} width={50} />
          <Input
            readOnly
            value={model.price ? model.price.hotelMadinahName : ""}
          />
        </div>
      </Responsive>

      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Saoudi Wakil : </label>
        <Input
          value={model.saoudiWakil}
          onChange={(saoudiWakil) => {
            _setmodel((prev) => {
              return { ...prev, saoudiWakil };
            });
          }}
        />
      </Responsive>
      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Makkah Mandoub : </label>
        <Input
          value={model.makkahMandoub}
          onChange={(makkahMandoub) => {
            _setmodel((prev) => {
              return { ...prev, makkahMandoub };
            });
          }}
        />
      </Responsive>
      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Madina Mandoub : </label>
        <Input
          value={model.madinahMandoub}
          onChange={(madinahMandoub) => {
            _setmodel((prev) => {
              return { ...prev, madinahMandoub };
            });
          }}
        />
      </Responsive>
    </div>
  ) : (
    ""
  );
};

export default AddEdit;
