export const surveyTitles = [
  " طريقة السّماع عن كنوز ",
  " أوّل مرّة ",
  " الرّضاء على  العمرة ",
  " الرّضاء على الفرع  ",
  " مرافق ",
  " الطيران",
  " فندق المدينة",
  " خدمات المدينة",
  " فندق مكّة",
  " خدمات مكّة",
  " مميّزات كنوز",
  " اقتراح كنوز للنّاس",
  " استعمال رمز التخفيض",
];
export const surveyStatedata = [
  { label: "أجاب", value: 1 },
  { label: " لم يجب على الهاتف", value: 2 },
  { label: "رقم غير صحيح", value: 3 },
  { label: "لم يكمل سبر الاراء", value: 4 },
  { label: "رفض الاجابة", value: 5 },
  { label: "إعادة الاتصال ", value: 6 },
];
export const goodBadResponse = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "سيئة", value: 3 },
];
export const UmrahState = [
  { label: "ممتازة ", value: 1 },
  { label: "جيدة", value: 2 },
  { label: "متوسطة/ مقبولة", value: 3 },
  { label: "سيئة", value: 4 },
];
export const yesOrNoResponse = [
  { label: "نعم", value: true },
  { label: "لا", value: false },
];

export const guide = [
  {
    label: "معاملة طيبة و  قدم برشة خدمات",
    value: 1,
  },
  { label: "عمل اللي عليه ", value: 2 },
  { label: " ما قامش بدوره كمرافق", value: 3 },
];
export const branchTreatment = [
  { label: "معاملة جيدة", value: 1 },
  { label: "معاملة عادية", value: 2 },
  { label: " معاملة سيئة من الموظفين", value: 3 },
];
export const BestInKounouz = [
  { label: "الخدمات و اللهوة", value: 1 },
  { label: "السعر", value: 2 },
  { label: "توفر العمرة في كل الأوقات", value: 3 },
  { label: "كل شيئ", value: 4 },
  { label: " لا شيئ", value: 5 },
];
export const heardOf = [
  { label: " زيارة الفرع", value: 6 },
  { label: "رئيس مجموعة", value: 5 },
  { label: "صديق/قريب", value: 4 },
  { label: "انستغرام", value: 3 },
  { label: "فايسبوك", value: 2 },
  { label: "تلفزة", value: 1 },
  { label: "وسيلة أخرى", value: 7 },
];
export const Colors = [
  "#e74c3c",
  "#7f8c8d",
  "#27ae60",
  "#2980b9",
  "orange",
  "Purple",
  "Teal",
];
export const GeneralStatColors = [
  "#27ae60",
  "#7f8c8d",
  "black",
  "#e67e22",
  "#e74c3c",
  "#8e44ad",
  "#22a6b3",
];
export const generalLabels = [
  "أجاب",
  " لم يجب على الهاتف",
  "رقم غير صحيح",
  "لم يكمل سبر الاراء",
  "رفض الاجابة",
  "إعادة الاتصال ",
  " رقم غير متوفّر",
];
